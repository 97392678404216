import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@bluebeam/react-skyline-button';
import { useTranslation, Trans } from 'react-i18next';
import { GenericMessageContainer } from '../../components/GenericMessageContainer';
import { TermsOfServiceModal } from '../../components/Modals/TermsOfServiceModal';
import { PasswordToggle } from '../../components/PasswordToggle/PasswordToggle';
import { ValidationError } from '../../components/ValidationError';
import { GreenCheck } from '../../components/GreenCheck';
import { FormLabel } from '../../components/FormLabel';
import { FormInput } from '../../components/FormInput';
import { Tooltip } from '../../components/Tooltip';
import { setSessionCookie } from '../../utils/cookies';
import { FieldValue, useValidateForm } from '../../hooks/useValidateForm';
import { createAccount, CreateAccountResponse } from '../../services/createAccount';
// restore this when we show language selector
//import { DropdownField } from '../../components/DropdownField';
import i18n from '../../i18n';

import * as formConstants from '../../utils/constants/createAccountFormConstants';
import * as testConstants from '../../utils/constants/testHTMLAttributeConstants';
import * as translationConstants from '../../utils/constants/i18nTranslationConstants';
import * as urlConstants from '../../utils/constants/urlConstants';

export const CreateAccount: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    formValues,
    setFormValues,
    formErrors,
    handleChange,
    formFieldsAreActive,
    setFormFieldsAreActive,
    isPasswordValid,
    isEmailValid,
    setIsEmailValid,
    validateAllFields,
    setEmailAlreadyExistsError
  } = useValidateForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isAcceptTOSButtonDisabled, setIsAcceptTOSButtonDisabled] = useState<boolean>(false);
  const [returnUrl, setReturnUrl] = useState<string | null>(null);
  const [selectedPreferredLanguageValue, setSelectedPreferredLanguageValue] = useState<string>('');
    // restore this to default to en-US when we show language selector
    //useState<string>('en-US');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const returnUrlParam = queryParams.get(urlConstants.RETURN_URL);

    if (returnUrlParam) {
      setReturnUrl(returnUrlParam);
      setSessionCookie(urlConstants.RETURN_URL, returnUrlParam);
    }

    // Checks the User-Agent for unsupported Revu versions
    const userAgent = navigator.userAgent;
    const unsupportedVersions = [
      'Bluebeam-Revu/20.2.15',
      'Bluebeam-Revu/20.2.20',
      'Bluebeam-Revu/20.2.30',
      'Bluebeam-Revu/20.2.40',
      'Bluebeam-Revu/20.2.50',
      'Bluebeam-Revu/20.2.60',
      'Bluebeam-Revu/20.2.70',
      'Chrome/84.0'
    ];

    if (unsupportedVersions.some((version) => userAgent.includes(version))) {
      navigate('/createaccount/unsupportedrevuversion');
    }

    const prefilledEmail = queryParams.get(urlConstants.EMAIL);
    if (prefilledEmail) {
      setFormValues({ ...formValues, email: prefilledEmail });
      setFormFieldsAreActive({ ...formFieldsAreActive, email: true });
    }

    const prefilledPreferredLanguage = queryParams.get(urlConstants.PREFERRED_LANGUAGE);
    if (prefilledPreferredLanguage) {
      // bluebeam_language query param can be 2 or 4 letters (e.g. ja or ja-JP)
      const languageMatch = translationConstants.LANGUAGE_OPTIONS.find(
        (option) =>
          option.value.toLowerCase() === prefilledPreferredLanguage.toLowerCase() ||
          option.value.startsWith(prefilledPreferredLanguage.toLowerCase())
      );

      if (languageMatch) {
        handlePreferredLanguageDropdownChange(languageMatch.value);
      }
    }
  }, []);

  const relevantFields: (keyof FieldValue)[] = [
    formConstants.FIRST_NAME_FIELD,
    formConstants.LAST_NAME_FIELD,
    formConstants.DISPLAY_NAME_FIELD,
    formConstants.EMAIL_FIELD,
    formConstants.PASSWORD_FIELD,
    formConstants.CONFIRM_PASSWORD_FIELD
  ];

  const canOpenModal: boolean =
    relevantFields.every((field) => formErrors[field] === '') &&
    relevantFields.every((field) => formFieldsAreActive[field]);

  const handlePreferredLanguageDropdownChange = (value: string) => {
    setSelectedPreferredLanguageValue(value);
    i18n.changeLanguage(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateAllFields();

    if (canOpenModal) {
      setShowModal(true);
    }
  };

  const handleAcceptTOS = async () => {
    setIsAcceptTOSButtonDisabled(true);

    try {
      const response: CreateAccountResponse = await createAccount(
        formValues,
        selectedPreferredLanguageValue
      );
      if (response.statusCode == 200) {
        navigate('/createaccount/confirmation');
      } else if (response.statusCode == 409) {
        setShowModal(false);
        setIsEmailValid(false);
        setEmailAlreadyExistsError();
      } else {
        navigate('/error');
      }
    } catch {
      navigate('/error');
    }

    setIsAcceptTOSButtonDisabled(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="m-auto my-[50px] w-[420px] sm:m-0 sm:w-full">
        <GenericMessageContainer title={t(translationConstants.CREATE_AN_ACCOUNT)}>
          <form
            onSubmit={handleSubmit}
            className="p-7 flex flex-col bg-[#F1F2F2] border-solid border-[1px] border-t-[#DADADA]"
          >
            <div className="flex flex-col">
              <FormLabel text={t(translationConstants.FIRST_NAME)} />
              <FormInput
                placeholder={t(translationConstants.FIRST_NAME_EXAMPLE)}
                value={formValues.firstName}
                onChange={handleChange(formConstants.FIRST_NAME_FIELD)}
                hasError={formErrors.firstName != ''}
              />
              <ValidationError hidden={!formErrors.firstName} text={formErrors.firstName} />
            </div>
            <div className="mt-3 flex flex-col">
              <FormLabel text={t(translationConstants.LAST_NAME)} />
              <FormInput
                placeholder={t(translationConstants.LAST_NAME_EXAMPLE)}
                value={formValues.lastName}
                onChange={handleChange(formConstants.LAST_NAME_FIELD)}
                hasError={formErrors.lastName != ''}
              />
              <ValidationError hidden={!formErrors.lastName} text={formErrors.lastName} />
            </div>
            <div className="mt-3 flex flex-col">
              <div className="inline-block">
                <FormLabel text={t(translationConstants.DISPLAY_NAME)} />
                <div className="ml-1 inline-block">
                  <Tooltip
                    text={formConstants.TOOLTIP_LABEL}
                    textOnHover={t(translationConstants.TOOLTIP_NAME)}
                  />
                </div>
              </div>
              <FormInput
                placeholder={t(translationConstants.DISPLAY_NAME_EXAMPLE)}
                value={formValues.displayName}
                onChange={handleChange(formConstants.DISPLAY_NAME_FIELD)}
                hasError={formErrors.displayName != ''}
              />
              <ValidationError hidden={!formErrors.displayName} text={formErrors.displayName} />
            </div>
{/*             <div className="mt-3 flex flex-col">
              <FormLabel text={t(translationConstants.PREFERRED_LANGUAGE)} />
              <DropdownField
                options={translationConstants.LANGUAGE_OPTIONS}
                value={selectedPreferredLanguageValue}
                onChange={handlePreferredLanguageDropdownChange}
              />
            </div> */}
            <div className="mt-3 flex flex-col">
              <div>
                <FormLabel text={t(translationConstants.EMAIL)} />
                <span className="ml-1 inline-block font-[450]">
                  ({t(translationConstants.EMAIL_DESCRIPTION)})
                </span>
              </div>
              <div className="relative">
                <FormInput
                  placeholder={t(translationConstants.EMAIL_EXAMPLE)}
                  value={formValues.email}
                  onChange={handleChange(formConstants.EMAIL_FIELD)}
                  isValid={isEmailValid}
                  hasError={formErrors.email != ''}
                />
                <div className="py-[10px] absolute right-0 top-0 bottom-0">
                  <GreenCheck hidden={!isEmailValid} />
                </div>
              </div>
              <ValidationError hidden={!formErrors.email} text={formErrors.email} />
            </div>
            <div className="mt-3 flex flex-col">
              <FormLabel text={t(translationConstants.PASSWORD)} />
              <div className="relative">
                <FormInput
                  type={showPassword ? 'text' : 'password'}
                  value={formValues.password}
                  onChange={handleChange(formConstants.PASSWORD_FIELD)}
                  hasError={formErrors.password != ''}
                  isValid={isPasswordValid}
                  testId={formConstants.PASSWORD_FIELD}
                />
                <div className="p-[10px] absolute right-0 top-0 bottom-0">
                  <div className="flex flex-row items-center">
                    <GreenCheck
                      hidden={!isPasswordValid}
                      testId={testConstants.PASSWORD_CHECK_ICON}
                    />
                    <PasswordToggle
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                      testId={testConstants.SHOW_PASSWORD_BUTTON}
                    />
                  </div>
                </div>
              </div>
              <span>
                <ValidationError
                  hidden={!formErrors.password}
                  text={formErrors.password}
                  testId={testConstants.PASSWORD_ERROR_SPAN}
                />
                <span className="text-[14px] text-light-gray font-[500] leading-5">
                  <Trans
                    i18nKey={translationConstants.PASSWORD_REQUIREMENT_HTML}
                    components={{
                      1: <span className="font-bold" />
                    }}
                  />
                </span>
              </span>
            </div>
            <div className="mt-3 flex flex-col">
              <FormLabel text={t(translationConstants.CONFIRM_PASSWORD)} />
              <FormInput
                type={showPassword ? 'text' : 'password'}
                value={formValues.confirmPassword}
                onChange={handleChange(formConstants.CONFIRM_PASSWORD_FIELD)}
                hasError={formErrors.confirmPassword != ''}
                testId={formConstants.CONFIRM_PASSWORD_FIELD}
              />
              <ValidationError
                hidden={!formErrors.confirmPassword}
                text={formErrors.confirmPassword}
              />
            </div>
            <Button
              className="mt-8 px-6 py-2 self-center bg-primary rounded-[0.25rem] w-fit text-white text-[16px] font-[650] capitalize hover:bg-[#286090]"
              variant="filled"
              type="submit"
              data-testid={testConstants.CREATE_ACCOUNT_BUTTON}
            >
              {t(translationConstants.CREATE_ACCOUNT)}
            </Button>
            <Link
              to={returnUrl ? returnUrl : '/manageaccount'}
              className="mt-[20px] mb-[-15px] font-[400] underline text-[#6e6e78] text-[15px]"
            >
              {t(translationConstants.ALREADY_REGISTERED)}
            </Link>
          </form>
        </GenericMessageContainer>
      </div>
      {showModal && (
        <TermsOfServiceModal
          onClose={handleCloseModal}
          onAccept={handleAcceptTOS}
          isAcceptTOSButtonDisabled={isAcceptTOSButtonDisabled}
        />
      )}
    </>
  );
};
